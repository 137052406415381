<template>

  <div :class="{flixFocus: focused}" :key="active.length" @mouseover="focused = true" @mouseleave="focused = false">

    <getLabel :data="data" :focus="focused" :key="focused" />

    <ul class="terms">
    <li v-for="(term, index) in data.values" :class="{active: active[index]}" :key="index+key" @click="getClick(index)">
      {{ $t('message.' + term) }}
    </li>
    </ul>

    <div v-if="data.description">
      <a href="#" @click.prevent="open = !open" class="flix-html-a flix-html-small">{{ $t('message.terms') }}</a>
      <p v-if="open" v-html="data.description"></p>
    </div>
  </div>

</template>

<script>
import getLabel from '@/components/formBuilder/parts/label'
export default {
  props: { data: Object, callback: Function },
  components: { getLabel },
  data () {
    return {
      active: {},
      focused: false,
      key: new Date().getTime(),
      open: false
    }
  },

  methods: {
    getActive (index) {
      return this.active[index]
    },

    getClick (index) {
      Object.keys(this.active).forEach(function (k) {
        this.active[k] = false
      }.bind(this))

      this.active[index] = true
      this.key = new Date().getTime()
    }
  },
  mounted () {
    Object.keys(this.data.values).forEach(function (k) {
      this.active[k] = false
    }.bind(this))
  }
}

</script>

  <style lang="sass" scoped>
    @import '@/components/formBuilder/style/variables.sass'
    @import '@/components/formBuilder/style/form/label.sass'
    @import '@/components/formBuilder/style/form/terms.sass'
  </style>
